import AppContextWrapper from '@app/context';

import type { AppProps } from 'next/app';

import 'animate.css';
import '@app/styles/index.scss';

const App = ({ Component, pageProps, router }: AppProps) => {
  return (
    <AppContextWrapper>
      <Component {...pageProps} key={router.asPath} />
    </AppContextWrapper>
  );
};

export default App;
