import { ChakraProvider } from '@chakra-ui/react';
import React from 'react';

import theme from '@app/theme';

import '@fontsource-variable/raleway';
import '@fontsource-variable/inter';

interface AppContextWrapperProps {
  children: React.ReactNode;
}

const AppContextWrapper = ({ children }: AppContextWrapperProps) => {
  return <ChakraProvider theme={theme}>{children}</ChakraProvider>;
};

export default AppContextWrapper;
